import React, { useEffect, useState } from "react";
import iconImg from "../../assets/images/icon.png";
import icon1Img from "../../assets/images/icon1.png";
import {
  useGetUserQuery,
  usePatchUserMutation,
} from "../../services/userSlice";
import SuccessModal from "../../components/modal/SuccessModal";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import { useGetBranchesQuery } from "../../services/branchSlice";
import {
  PORTALS,
  POSTS,
  ROLES,
  SUB_PORTALS,
  TYPES,
} from "../../constants/Constants";
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Check from "../../components/form/Check";
import Bar from "../../components/form/Bar";
import ContainerCard from "../../components/ui/ContainerCard";
import ImageInput from "../../components/form/ImageInput";
import Icons from "../../components/ui/Icons";
import TableContainerCard from "../../components/ui/TableContainerCard";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import Loading from "../../components/ui/Loading";
import { Link, useParams } from "react-router-dom";
import SelectWithLabels from "../../components/form/SelectWithLabels";
import Button from "../../components/form/Button";
import UserDeleteModal from "../../components/modal/UserDeleteModal";
import Api from "../../constants/Api";

const EditUser = () => {
  const { id } = useParams();
  const userRes = useGetUserQuery(id);
  const branchesRes = useGetBranchesQuery();
  const [patchUser, patchUserRes] = usePatchUserMutation();
  const [activePortal, setActivePortal] = useState(PORTALS[0]);
  const [activeSubPortal, setActiveSubPortal] = useState(
    PORTALS[0].subPortals[0]
  );
  const [formEnabled, setFormEnabled] = useState(false);

  const [brahmvidyaAccessGranted, setBrahmvidyaAcccessGranted] =
    useState(false);
  const [educationAccessGranted, setEducationAcccessGranted] = useState(false);

  const activeBarClassName = "px-3 py-1 bg-secondary text-white rounded-top";
  const inactiveBarClassName = "px-3 py-1";

  const consentLabel =
    "By checking this box I hereby give my consent for the Utility service grants across multiple portals (if granted) and that the user will abide by polices, terms and conditions established by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements.";

  const renderCheck = (name, option, defaultValue) => (
    <Check
      key={name + option}
      name={name + option}
      label={option}
      defaultChecked={defaultValue}
      disabled={!formEnabled}
      containerClass={"me-3"}
    />
  );

  const renderPortal = (portal) => (
    <div
      key={portal}
      className={
        activePortal.name === portal.name
          ? activeBarClassName
          : inactiveBarClassName
      }
      onClick={() => {
        setActivePortal(portal);
        setActiveSubPortal(portal.subPortals[0]);
      }}
    >
      {portal.name}
    </div>
  );

  const renderSubPortal = (subPortal) => (
    <div
      key={subPortal.name}
      className={
        activeSubPortal.name === subPortal.name
          ? activeBarClassName
          : inactiveBarClassName
      }
      onClick={() => setActiveSubPortal(subPortal)}
    >
      {subPortal.name}
    </div>
  );

  const toCamelCase = (str) => {
    return (
      str
        // Split the string into words
        .split(" ")
        // Map each word to a function that capitalizes it (except the first word)
        .map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        // Join the words back into a single string
        .join("")
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      let body = new FormData();

      if (form["User Photo"].files.length > 0) {
        body.append("profilePicture", form["User Photo"].files[0]);
      }

      body.append("name", form["Name"].value);
      body.append("sUid", form["SUID"].value);
      body.append("branchId", form["Branch Id"].value);
      body.append("email", form["Email"].value);
      body.append("phoneNumber", form["Phone No"].value);
      body.append("post", form["Post"].value);

      body.append("userName", form["Username"].value);
      body.append("password", form["Password"].value);
      body.append("pin", form["Pin"].value);
      body.append("status", form["Status"].value);
      body.append("type", form["Type"].value);

      body.append(
        "brahmvidhyaGrantAccess",
        form["Brahmvidhya Grant Access"]?.checked
      );
      body.append(
        "brahmvidhyaSandhyaSabha",
        form["Brahmvidhya Sandhya Sabha"]
          ? form["Brahmvidhya Sandhya Sabha"].checked
          : false
      );
      body.append(
        "brahmvidhyaCoursebooks",
        form["Brahmvidhya Coursebooks"]
          ? form["Brahmvidhya Coursebooks"].checked
          : false
      );
      body.append(
        "brahmvidhyaAssignments",
        form["Brahmvidhya Assignments"]
          ? form["Brahmvidhya Assignments"].checked
          : false
      );
      body.append(
        "brahmvidhyaDownloadables",
        form["Brahmvidhya Downloadables"]
          ? form["Brahmvidhya Downloadables"].checked
          : false
      );
      body.append(
        "brahmvidhyaTraining",
        form["Brahmvidhya Training"]
          ? form["Brahmvidhya Training"].checked
          : false
      );
      body.append(
        "brahmvidhyaICGSPinwheel",
        form["Brahmvidhya ICGS Pinwheel"]
          ? form["Brahmvidhya ICGS Pinwheel"].checked
          : false
      );

      body.append(
        "educationGrantAccess",
        form["Education Grant Access"]?.checked
      );
      body.append(
        "educationCirculars",
        form["Education Circulars"]
          ? form["Education Circulars"].checked
          : false
      );
      body.append(
        "educationSchool",
        form["Education School"] ? form["Education School"].checked : false
      );
      body.append(
        "educationHostel",
        form["Education Hostel"] ? form["Education Hostel"].checked : false
      );
      body.append(
        "educationEducation",
        form["Education Education"]
          ? form["Education Education"].checked
          : false
      );
      body.append(
        "educationKanyaGurukul",
        form["Education Kanya Gurukul"]
          ? form["Education Kanya Gurukul"].checked
          : false
      );
      body.append(
        "educationDownloadables",
        form["Education Downloadables"]
          ? form["Education Downloadables"].checked
          : false
      );
      body.append(
        "educationArchives",
        form["Education Archives"] ? form["Education Archives"].checked : false
      );

      body.append(
        "spmkBrahmvidyaAccess",
        form[SUB_PORTALS.SPMK_BRAHMVIDYA + "Grant Access"]?.checked
      );
      body.append(
        "mainBranchOfficeAccess",
        form[SUB_PORTALS.MAIN_BRANCH_OFFICE + "Grant Access"]?.checked
      );
      body.append(
        "branchErpAccess",
        form[SUB_PORTALS.BRANCH_ERP + "Grant Access"]?.checked
      );

      body.append(
        "vrcAppAccess",
        form[SUB_PORTALS.VRC_PORTAL + "Grant Access"]?.checked
      );


      body.append(
        "sgrsKaryakarAppAccess",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Grant Access"]?.checked
      );

      body.append(
        "zoneSanchalak",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Zone Sanchalak"]?.checked
      );
      body.append(
        "balMandal",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Bal Mandal"]?.checked
      );
      body.append(
        "devoteeCaring",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Devotee Caring"]?.checked
      );
      body.append(
        "gurukulParivar",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Gurukul Parivar"]?.checked
      );
      body.append(
        "prerakprasang",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Prerak Prasang"]?.checked
      );
      body.append(
        "satsangSamuday",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Satsang Samuday"]?.checked
      );
      body.append(
        "mandalMis",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Mandal Mis"]?.checked
      );
      body.append(
        "santsangPravrutti",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Santsang Pravrutti"]?.checked
      );
      body.append(
        "eAttendence",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "E Attendence"]?.checked
      );
      body.append(
        "dhunMandal",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Dhun Mandal"]?.checked
      );
      body.append(
        "haribhaktaSambhal",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Haribhakta Sambhal"]?.checked
      );
      body.append(
        "sevaDal",
        form[SUB_PORTALS.SGRS_KARAYAKAR_APP + "Seva Dal"]?.checked
      );

      patchUser({ id, body }).then((res) => {
        if (!res.error) {
          userRes.refetch();
          form.classList.remove("was-validated");
        }
      });
    } else form.classList.add("was-validated");
  };

  useEffect(() => {
    if (userRes.isSuccess && userRes.data) {
      setBrahmvidyaAcccessGranted(userRes.data?.brahmvidyaPortalAccess);
      setEducationAcccessGranted(userRes.data?.educationPortalAccess);
    }
  }, [userRes.isSuccess, userRes.data]);

  if (branchesRes.isLoading || userRes.isLoading) return <Loading />;
  if (branchesRes.isError) return <ApiErrorModal response={branchesRes} />;
  if (userRes.isError) return <ApiErrorModal response={userRes} />;

  const branches = branchesRes.data.map((branch) => ({
    label: branch.id + " " + branch.branchName,
    value: branch.id,
  }));
  const user = userRes.data;

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.userManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">
                {formEnabled ? "Edit" : "View"}
              </span>{" "}
              User
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Check
              name={"Check to edit"}
              setChecked={setFormEnabled}
              containerClass={"me-3"}
              required
            />
            <UserDeleteModal id={id} />
            <Link to={-1} className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      )}
    >
      {patchUserRes.isError && <ApiErrorModal response={patchUserRes} />}
      {patchUserRes.isSuccess && (
        <SuccessModal
          label="User Created"
          message="User successfully created."
          to={{ link: "/UserManagement", text: "Go to User Management" }}
        />
      )}

      <form onSubmit={onSubmit} noValidate>
        <Bar label={"Primary Details"}>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput
                name={"User Photo"}
                defaultValue={
                  Api.PROFILE_PICTURE_URL +
                  user?.profilePicture?.id +
                  user?.profilePicture?.ext
                }
                disabled={!formEnabled}
              />
            </div>

            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input
                  name={"Name"}
                  defaultValue={user.name}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"SUID"}
                  defaultValue={user.sUid}
                  disabled={!formEnabled}
                  required
                />
                <SelectWithLabels
                  name={"Branch Id"}
                  defaultValue={user.branchId}
                  disabled={!formEnabled}
                  options={branches}
                />
                <Input
                  name={"Email"}
                  type={"email"}
                  defaultValue={user.email}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Phone No"}
                  defaultValue={user.phoneNumber}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Post"}
                  defaultValue={user.post}
                  disabled={!formEnabled}
                  required
                />
                {/* <Select name={"Post"} options={POSTS} defaultValue={user.post} disabled={!formEnabled} /> */}
              </div>
            </div>
          </div>
        </Bar>

        <Bar label={"Credentials"}>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 mb-3">
              <Input
                name={"Username"}
                defaultValue={user.userName}
                disabled={!formEnabled}
                required
              />
              <Input
                name={"Password"}
                type={"password"}
                defaultValue={user.password}
                disabled={!formEnabled}
                required
              />
              <Select
                name={"Status"}
                options={["Active", "Suspended", "Blocked"]}
                defaultValue={user.status}
                disabled={!formEnabled}
              />
              <Select
                name={"Type"}
                options={["Developer", "Saint", "Devotee", "Office"]}
                defaultValue={user.type}
                disabled={!formEnabled}
              />
            </div>
          </div>
        </Bar>

        <Bar label={"Select portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {PORTALS.map(renderPortal)}
          </div>
        </Bar>

        <Bar label={"Select sub portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {activePortal.subPortals.map(renderSubPortal)}
          </div>
        </Bar>

        <Bar label={"Access management"} containerClass={"flex-column"}>
          <div
            className={
              activeSubPortal.name === "ICGS Brahmvidhya"
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={"Brahmvidhya Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              checked={brahmvidyaAccessGranted}
              setChecked={setBrahmvidyaAcccessGranted}
              disabled={!formEnabled}
            />
            {brahmvidyaAccessGranted &&
              PORTALS[0].subPortals[0].accesses.map((option) =>
                renderCheck(
                  "Brahmvidhya ",
                  option,
                  user.brahmvidyaPortalAccess &&
                  user.brahmvidyaPortalAccess[toCamelCase(option)]
                )
              )}
          </div>
          <div
            className={
              activeSubPortal.name === "ICGS Education"
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={"Education Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              checked={educationAccessGranted}
              setChecked={setEducationAcccessGranted}
              disabled={!formEnabled}
            />
            {educationAccessGranted &&
              PORTALS[0].subPortals[1].accesses.map((option) =>
                renderCheck(
                  "Education ",
                  option,
                  user.educationPortalAccess &&
                  user.educationPortalAccess[toCamelCase(option)]
                )
              )}
          </div>

          <div
            className={
              activeSubPortal.name === SUB_PORTALS.SPMK_BRAHMVIDYA
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={SUB_PORTALS.SPMK_BRAHMVIDYA + "Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.spmkBrahmvidyaAccess}
              disabled={!formEnabled}
            />
          </div>
          <div
            className={
              activeSubPortal.name === SUB_PORTALS.MAIN_BRANCH_OFFICE
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={SUB_PORTALS.MAIN_BRANCH_OFFICE + "Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.mainBranchOfficeAccess}
              disabled={!formEnabled}
            />
          </div>

          <div
            className={
              activeSubPortal.name === SUB_PORTALS.BRANCH_ERP
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={SUB_PORTALS.BRANCH_ERP + "Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.branchErpAccesses?.length > 0}
              disabled={!formEnabled}
            />
          </div>

          <div
            className={
              activeSubPortal.name === SUB_PORTALS.SGRS_KARAYAKAR_APP
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess}
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Zone Sanchalak"}
              label={"Zone Sanchalak"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.zoneSanchalak
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Bal Mandal"}
              label={"Bal Mandal"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess?.balMandal}
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Devotee Caring"}
              label={"Devotee Caring"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.devoteeCaring
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Gurukul Parivar"}
              label={"Gurukul Parivar"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.gurukulParivar
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Prerak Prasang"}
              label={"Prerak Prasang"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.prerakprasang
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Satsang Samuday"}
              label={"Satsang Samuday"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.satsangSamuday
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Mandal Mis"}
              label={"Mandal Mis"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess?.mandalMis}
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Santsang Pravrutti"}
              label={"Santsang Pravrutti"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.santsangPravrutti
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "E Attendence"}
              label={"E Attendence"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess?.eAttendence}
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Dhun Mandal"}
              label={"Dhun Mandal"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess?.dhunMandal}
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Haribhakta Sambhal"}
              label={"Haribhakta Sambhal"}
              containerClass={"me-3"}
              defaultChecked={
                userRes.data?.sgrsKaryakarAppAccess?.haribhaktaSambhal
              }
              disabled={!formEnabled}
            />
            <Check
              name={SUB_PORTALS.SGRS_KARAYAKAR_APP + "Seva Dal"}
              label={"Seva Dal"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.sgrsKaryakarAppAccess?.sevaDal}
              disabled={!formEnabled}
            />
          </div>

          <div
            className={
              activeSubPortal.name === SUB_PORTALS.VRC_PORTAL
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={SUB_PORTALS.VRC_PORTAL + "Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              defaultChecked={userRes.data?.vrcAppAccess}
              disabled={!formEnabled}
            />


          </div>



        </Bar>

        <Bar label={"CONSENT"} containerClass={"flex-column align-items-start"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  mb-3">
            <Input name={"Pin"} disabled={!formEnabled} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check
              name={"consent"}
              label={consentLabel}
              containerClass={"col col-lg-10"}
              disabled={!formEnabled}
              required
            />

            <Button
              className="col col-lg-2 btn btn-secondary rounded-pill text-nowrap"
              res={patchUserRes}
              loadingLabel={"Updating"}
              disabled={!formEnabled}
            >
              Update User
            </Button>
          </div>
        </Bar>
      </form>
    </ResponsiveContainerCard>
  );
};

export default EditUser;
