
const ICGS_BRAHMVIDYA = 'ICGS Brahmvidhya'
const ICGS_EDUCATION = 'ICGS Education'

const SPMK_BRAHMVIDYA = 'SPMK Brahmvidhya'
const MAIN_BRANCH_OFFICE = 'Main Branch Office'
const VRC_PORTAL = 'VRC PORTAL'
const BRANCH_ERP = 'Branch ERP'
const SGRS_KARAYAKAR_APP = 'SGRS Karyakar App'
const SUB_PORTALS = { ICGS_BRAHMVIDYA, ICGS_EDUCATION, SPMK_BRAHMVIDYA, MAIN_BRANCH_OFFICE, BRANCH_ERP, SGRS_KARAYAKAR_APP, VRC_PORTAL }

const ICGS_PORTAL = 'ICGS PORTAL'
const SPMK_PORTAL = 'SPMK PORTAL'
const RESEARCH_PORTAL = 'RESEARCH PORTAL'
const PORTALS = [
    {
        name: ICGS_PORTAL,
        subPortals: [
            {
                name: ICGS_BRAHMVIDYA,
                accesses: ["Sandhya Sabha", "Coursebooks", "Assignments", "Downloadables", "Training", "ICGS Pinwheel"]
            },
            {
                name: ICGS_EDUCATION,
                accesses: ["Circulars", "School", "Hostel", "Education", "Kanya Gurukul", "Downloadables", "Archives"]
            }
        ]
    },
    {
        name: SPMK_PORTAL,
        subPortals: [
            {
                name: SPMK_BRAHMVIDYA,
                accesses: []
            },
            {
                name: MAIN_BRANCH_OFFICE,
                accesses: []
            }, {
                name: BRANCH_ERP,
                accesses: []
            }, {
                name: SGRS_KARAYAKAR_APP,
                accesses: []
            }
        ]
    },
    {
        name: RESEARCH_PORTAL,
        subPortals: [
            {
                name: VRC_PORTAL,
                accesses: []
            },
          
        ]
    },
]

const USER = 'User'
const ADMIN = 'Admin'
const ROLES = { USER, ADMIN }

const BASIC = 'Basic'
const ADVANCE = 'Advance'
const PRO = 'Pro'
const TYPES = { BASIC, ADVANCE, PRO }

const HEAD = 'Head'
const BRANCH = 'Branch'
const POSTS = { USER, HEAD, BRANCH }

module.exports = { PORTALS, SUB_PORTALS, ROLES, TYPES, POSTS }